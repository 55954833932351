<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>
  <div class="link-div">
    <span style="font-size: 13px">您当前的位置：</span>
    <el-link @click="toIndex">首页</el-link>
    <span style="font-size: 13px">&nbsp;>&nbsp;</span>
    <el-link @click="toCurrent">服务体系</el-link>
    <p class="link-p"></p>
  </div>
  <div class="module-div" v-for="data in serviceData" :key="data">
    <el-row :gutter="20" style="margin-left:20px">
      <div style="width: 260px">
        <img :src="data.img" class="image" />
      </div>
      <div
        class="describe"
        @mouseover="addActive($event)"
        @mouseout="removeActive($event)"
      >
        <div style="margin-top: 10px; margin-bottom: 10px">
          <el-link
            style="font-size: 16px; color: #000"
            @click="toServiceDetails(data)"
            >{{ data.name }}</el-link
          >
        </div>
        <label>{{ data.date }}</label>
        <p class="describe-p">
          {{ data.describe }}
        </p>
      </div>
    </el-row>
  </div>
</template>

<script>
import qt from "@/assets/image/product-center/qt.png";
import smD2 from "@/assets/image/product-center/smD2.png";
import pay from "@/assets/image/service-system/pay.jpg";
import sczc from "@/assets/image/service-system/sczc.jpg";
export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {
    return {
      serviceData: [
        {
          img: smD2,
          name: "系统开户流程",
          date: "2021-09-15",
          describe:
            "刷脸支付刷脸支付商用元年，刷脸支付AI新科技，打造高效、智能化收银管理平台，引领支付行业新方向。新支付、新科技、新零售、新未来，抓住趋势用智慧营造赋能未来。",
        },
        {
          img: pay,
          name: "支付方式/支付通道",
          date: "2021-09-15",
          describe:
            "2019年，产业迎来新革命，支付4.0时代正式开启，未来10年都是刷脸支付的时代。创造多样化线下商户智能收银场景，提升收银效率",
        },
        {
          img: qt,
          name: "线上系统",
          date: "2021-09-15",
          describe:
            "一直专注于多生物特征识别的核心技术、公安信息化综合应用、视频侦查技术和大数据综合应用等四大领域，利用人工智能实现“人”的发现、寻找、检验、认定，为公安打击犯罪，及公共安全防范提供综合解决方案。针对现场",
        },
        {
          img: sczc,
          name: "市场支持",
          date: "2021-09-15",
          describe:
            "提供畅立收科技产品的活动政策,针对现场勘验工作相关的信息采集、信息处理、资料存储、卷宗制作、检验鉴定、物证保管、案件串并及信息发布等重要环节,刑事技术整体解决方案,人员信息综合采集,人脸识别远程监控报警系统",
        },
        {
          img: pay,
          name: "客服支持",
          date: "2021-09-15",
          describe:
            " 72小时的技术支持,为全国合作商提供行业解决方案支持,针对现场勘验工作相关的信息采集、信息处理、资料存储、卷宗制作、检验鉴定、物证保管、案件串并及信息发布等重要环节,刑事技术整体解决方案,人员信息综合采集,人脸识别远程监控报警系统",
        },
      ],
    };
  },
  methods: {
    addActive($event) {
      $event.currentTarget.className = "describes";
    },
    removeActive($event) {
      $event.currentTarget.className = "describe";
    },
    toServiceDetails(data) {
      this.$router.push({
        path: "/ServiceDetails",
        query: {
          name: data.name,
          img: data.img,
          describe: data.describe,
          date: data.date,
        },
      });
    },
    toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    toCurrent() {
      this.$router.push({
        path: "/service",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
.describe {
  padding-left: 20px;
  width: 1020px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
}
.describe-p {
  line-height: 30px;
  font-size: 14px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 12px;
}
.describes {
  padding-left: 20px;
  width: 1020px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
  background: #098af3;
}
.el-link {
  color: #000;
  margin-top: -3px;
}
.link-p {
  border-bottom: 1px solid #bbbbbdec;
}
.link-div {
  padding-left: 270px;
  text-align: left;
  margin-top: 30px;
  padding-right: 270px;
}
.module-div {
  margin-top: 50px;
  padding-right: 270px;
  margin-bottom: 50px;
}
</style>